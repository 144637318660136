import { z } from "zod";
import { Tool } from "./constants";

export const searchWorkouts: Tool = {
  name: "searchWorkouts",
  toolInUseText: "Searching Ladder workouts...",
  description:
    "Search for Ladder workouts based on user queries. Use this tool to find specific workouts or workout types that match the user's request. This can include searching by workout type, duration, intensity, target muscle groups, or specific exercises. Only call this once per query. If no relevant workouts are found, inform the user.",
  parameters: z.object({
    query: z.string().describe("The user's workout search query"),
    match_count: z
      .number()
      .optional()
      .describe("The number of workouts to return"),
  }),
  execute: async ({
    query,
    match_count = 4,
  }: {
    query: string;
    match_count?: number;
  }) => {
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await fetch(`/api/arnie/search/workouts`, {
        method: "POST",
        headers,
        body: JSON.stringify({ query, match_count }),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to search workouts. Status: ${response.status}, StatusText: ${response.statusText}`
        );
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error searching workouts:", error);
      return `Failed to search workouts. Error: ${error.message}`;
    }
  },
};
