import { z } from "zod";
import { Tool } from "./constants";
import { listTeams } from "./list-teams";

export const getTeamDetailsByID: Tool = {
  env: "server",
  name: "getTeamDetailsByID",
  toolInUseText: "Getting team details...",
  description:
    "Get the details of a team by its ID. Use this to get more information about a team.",
  parameters: z.object({
    ids: z
      .array(z.string())
      .describe("The ID(s) of the team to get details for."),
  }),
  execute: async ({ ids }: { ids: string[] }) => {
    const teams = await listTeams();
    const details = teams.filter((team) => ids.includes(team.id));
    return details;
  },
};
