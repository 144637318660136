import Cookies from "js-cookie";
import { Team } from "queries/teams";
import { SESSION_KEY } from "ui/lib/constants";
import { isDev } from "ui/lib/helpers";

export const listTeams = async (): Promise<Team[]> => {
  const token = Cookies.get(SESSION_KEY);
  const url = `${process.env.NEXT_PUBLIC_API_BASE_URL}/v1/listTeams${isDev ? "?authUserID=arnold" : ""}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
  });

  if (!response.ok) {
    throw new Error("network response was not ok");
  }

  const data = await response.json();
  return data.teams;
};
