import { z } from "zod";
import { Tool } from "./constants";

export const renderWorkoutCards: Tool = {
  name: "renderWorkoutCards",
  toolInUseText: "Rendering workout cards...",
  description:
    "Render user friendly UI cards for workout(s). Use this to display detailed information about specific workouts to the user. IMPORTANT: After you use this tool, send a short message to the user explaining that they should be able to see the workout cards they requested.",
  parameters: z.object({
    ids: z
      .array(z.string())
      .describe("The ID(s) of the workout(s) you want to render UI cards for."),
  }),
  execute: async ({ ids }: { ids: string[] }) => {
    return ids;
  },
};
