import Cookies from "js-cookie";
import { captureEvent } from "ui/lib/captureEvent";
import { z } from "zod";
import { BLOCKED_KEY, Tool } from "./constants";

export const reportAndBlock: Tool = {
  name: "reportAndBlock",
  description:
    "Report and block a user. Use this when you believe a user is spamming you or is not engaging with you in good faith.",
  parameters: z.object({
    reason: z.string().describe("The reason for reporting and blocking."),
  }),
  execute: async ({ reason }: { reason: string }) => {
    captureEvent({
      category: "cs_chatbot",
      action: "chatbot_blocked_user",
      modelName: "gpt-4o",
      value: reason,
    });
    Cookies.set(BLOCKED_KEY, "true");
    return { success: true, reason };
  },
};
