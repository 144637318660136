import { z } from "zod";
import { Tool } from "./constants";

export const isServer = () => typeof window === "undefined";

const getApiUrl = (path: string) => {
  if (isServer()) {
    return `${process.env.NEXT_PUBLIC_DOMAIN}${path}`;
  }
  return path;
};

const searchForMovements: Tool = {
  name: "searchForMovements",
  description:
    "Useful for searching for movements in the Ladder movement library. You can use this to search for many movements at at the same time by using an array. Movement names are structured as '[family name] • [modifier] | [equipment]'. Avoid simple searches, like 'yoga' or 'chest movement', instead search for specific yoga movements, like 'childs pose', or 'downward dog' or specific chest movements, like 'pushups', or 'chest press'. The more specific the better. Search is semantic, so be specific and descriptive. Output is the result of the search, consisting of movement names and IDs. If the results aren't what the user is looking for, call this function again with a refined search to find more movements.",
  parameters: z.object({
    team_id: z
      .string()
      .optional()
      .describe(
        "The team ID whos movements you want to search for, if you have it."
      ),
    equipment: z
      .string()
      .optional()
      .describe("The equipment your client is wanting to use."),
    searches: z.array(
      z.object({
        movementName: z
          .string()
          .describe(
            "The specific movement or exercise you are looking for. Remember to be specific. Include the equipment, modifier (left, right), and movement family name in your search."
          ),
        match_count: z
          .number()
          .default(3)
          .describe(
            "How many results would you like to be returned for this search? Search for at least 3 at a time to ensure you find what you're looking for."
          ),
      })
    ),
  }),
  execute: async ({
    team_id,
    equipment = "",
    searches: movementsToSearchFor,
  }) => {
    const requestContent = movementsToSearchFor.map(
      ({ movementName, match_count }) => ({
        text: `${movementName} ${equipment}`.trim(),
        match_count: Number(match_count) >= 3 ? match_count : 3,
      })
    );

    const response = await fetch(
      getApiUrl(`/api/generate-and-search-embeddings`),
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ content: requestContent, team_id }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    const simplifiedResults = data
      .filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.name === item.name)
      )
      .map(({ name, equipment, idInt }) => ({
        name,
        id: idInt,
        equipment: equipment?.name || "",
      }));

    return { simplifiedResults, results: data, movementsToSearchFor };
  },
};

export { searchForMovements };
