import { z } from "zod";
import { Tool } from "./constants";

const insertMovement: Tool = {
  name: "insertMovement",
  description:
    "Useful for adding or inserting movements into the workout as requested by your client. If you are moving a movement from one section to another, you will need to remove it from the original section first.",
  parameters: z.object({
    movements: z.array(
      z.object({
        insertionId: z
          .string()
          .describe(
            "Where should the new movement be inserted in the workout? Provide the id of the movement or section you want to insert the new movement after."
          ),
        id: z.string().describe("The ID of the movement you want to insert."),
      })
    ),
  }),
  execute: async ({ movements }) => {
    try {
      return { movements };
    } catch (error) {
      return `Failed to execute script: ${error.message}`;
    }
  },
};

export { insertMovement };
