// File: src/api/tools/alertCoach.ts

import * as Sentry from "@sentry/nextjs";
import { z } from "zod";
import { Tool } from "./constants";

interface AlertDetails {
  memberId: string;
  memberName: string;
  roomId: string;
}

async function notifyCoach(alertDetails: AlertDetails) {
  const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
  const authToken = process.env.ARNOLD_SESSION_KEY;

  if (!baseUrl || !authToken) {
    throw new Error("Missing required environment variables");
  }

  try {
    const body = JSON.stringify({
      actionFlag: "coachGeneral",
      roomID: alertDetails.roomId,
    });

    // Set the action flag
    const flagResponse = await fetch(`${baseUrl}/v1/updateLegacyRoom`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body,
    });

    if (!flagResponse.ok) {
      throw new Error(`Failed to set action flag: ${flagResponse.statusText}`);
    }

    console.log(
      `Coach alerted for member ${alertDetails.memberName} (${alertDetails.memberId}) in room ${alertDetails.roomId}`
    );
  } catch (error) {
    console.error("Error in notifyCoach:", error);
    throw error;
  }
}

export const alertCoach: Tool = {
  name: "alertCoach",
  toolInUseText: "Alerting your coach...",
  description:
    "Use this tool to alert a coach when encountering topics or questions that require human expertise or intervention. This includes but is not limited to: health concerns, pregnancy-related questions, mental health issues, or any other sensitive topics that an AI is not equipped to handle safely or completely.",
  parameters: z.object({
    memberId: z.string().describe("The unique identifier of the member"),
    memberName: z.string().describe("The name of the member"),
    roomId: z.string().describe("The unique identifier of the chat room"),
  }),
  env: "server",
  execute: async (alertDetails: AlertDetails) => {
    try {
      await notifyCoach(alertDetails);

      Sentry.addBreadcrumb({
        category: "coach_alert",
        message: "Coach alerted for member issue",
        level: "info",
        data: {
          memberId: alertDetails.memberId,
          roomId: alertDetails.roomId,
        },
      });

      return {
        success: true,
        message:
          "Your coach has been alerted and will review your question as soon as possible. Please inform the member.",
      };
    } catch (error) {
      console.error("Error in alertCoach tool:", error);
      Sentry.captureException(error, {
        extra: { message: "Error in alertCoach tool", alertDetails },
      });
      return {
        success: false,
        message:
          "I apologize, but there was an error while trying to alert a coach. Please try again later or contact support directly if this is urgent.",
      };
    }
  },
};
