import { z } from "zod";

export type Tool = {
  name: string;
  env?: string;
  toolInUseText?: string;
  description: string;
  parameters: z.ZodObject<any>;
  execute: (...args: any[]) => Promise<any>;
};

export const BLOCKED_KEY = "USER_BLOCKED_BY_ARNIE";
