import { CoreMessage } from "ai";
import { z } from "zod";
import { alertCoach } from "./alert-coach";
import { Tool } from "./constants";
import { deleteMovement } from "./delete-movement";
import { getTeamDetailsByID } from "./get-team-by-id";
import { insertMovement } from "./insert-movement";
import { renderTeamCards } from "./render-team-cards";
import { renderWorkoutCards } from "./render-workout-cards";
import { replaceMovement } from "./replace-movement";
import { reportAndBlock } from "./report-and-block";
import { searchKnowledgeBase } from "./search-knowledge-base";
import { searchForMovements } from "./search-movements";
import { searchTeams } from "./search-teams";
import { searchWorkouts } from "./search-workouts";

const allTools = {
  reportAndBlock,
  alertCoach,
  searchKnowledgeBase,
  getTeamDetailsByID,
  searchTeams,
  searchWorkouts,
  renderTeamCards,
  renderWorkoutCards,
  searchForMovements,
  replaceMovement,
  insertMovement,
  deleteMovement,
  // open workout
  // start workout
  // end workout
  // log journal
  // move through workout
  // play pause workout
};

export const getTool = (toolName: string, prop?: string): Tool | string =>
  prop ? allTools[toolName][prop] : allTools[toolName];

export const getTools = (filter?: string[]): { [key: string]: Tool } => {
  if (!filter) return allTools;
  return Object.fromEntries(
    Object.entries(allTools).filter(([key]) => filter.includes(key))
  );
};

export const getAIToolsForServer = (
  filter?: string[]
): Record<string, { description: string; parameters: z.ZodObject<any> }> => {
  const tools = getTools(filter);
  return Object.fromEntries(
    Object.entries(tools).map(([key, tool]) => [
      key,
      {
        description: tool.description,
        parameters: tool.parameters,
        execute: tool.env === "server" ? tool.execute : undefined,
      },
    ])
  );
};

export const executeTool = async (
  toolName: string,
  args: any
): Promise<any> => {
  const tool = allTools[toolName as keyof typeof allTools];
  if (!tool) {
    throw new Error(`Tool ${toolName} not found`);
  }
  if (tool.env === "server" && typeof window !== "undefined") {
    return;
  }
  return tool.execute(args);
};

export const getAIMessagesForServer = (messages: CoreMessage[]) => {
  if (!messages) return messages;
  return messages.flatMap((message) => {
    // @ts-ignore
    if (!message.toolInvocations) return [message];

    // @ts-ignore
    return message.toolInvocations.flatMap(
      ({ toolCallId, toolName, args, result }) => {
        const toolCallMessage: CoreMessage = {
          role: "assistant",
          content: [
            {
              type: "tool-call",
              toolName,
              toolCallId,
              args,
            },
          ],
        };

        const toolMessage: CoreMessage = {
          role: "tool",
          content: [
            {
              result:
                toolName === "renderTeamCards"
                  ? "team cards rendered successfully. let the user know they should be able to see them"
                  : result,
              type: "tool-result",
              toolCallId,
              toolName,
            },
          ],
        };

        return [toolCallMessage, toolMessage];
      }
    );
  });
};
