import * as Sentry from "@sentry/nextjs";
import debounce from "lodash/debounce";
import { useRef } from "react";
import { toast } from "react-hot-toast";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import {
  getDetailedWorkoutSession,
  getWorkoutByID,
  GetWorkoutOptions,
  updateWorkoutByID,
} from "./services";
import { Workout } from "./types";

type props = UseQueryOptions<Workout, unknown, Workout, string[]> & {
  options?: GetWorkoutOptions;
};

const useWorkoutByID = (workoutID: string, props?: props) => {
  const { options, ...queryProps } = props || {};
  const { data, error, isLoading, refetch, isError } = useQuery(
    [`workout`, workoutID],
    getWorkoutByID(workoutID, options),
    {
      refetchOnWindowFocus: false,
      enabled: !!workoutID,
      ...queryProps,
    }
  );

  return {
    data,
    isError,
    error,
    refetch,
    isLoading,
  };
};

const useDetailedWorkoutSession = (workoutSessionID: string) => {
  const { data, error, isLoading, refetch, isError } = useQuery(
    [`detailedWorkoutSession`, `${workoutSessionID}`],
    () => getDetailedWorkoutSession(workoutSessionID),
    {
      refetchOnWindowFocus: false,
      enabled: !!workoutSessionID,
    }
  );

  return {
    data,
    error,
    isLoading,
    refetch,
    isError,
  };
};

interface MutationVariables {
  id: string;
  getUpdatedWorkout: (prevWorkout: any) => any; // Replace 'any' with the actual workout type
}
interface MutationContext {
  previousWorkout: any; // Replace 'any' with the actual workout type
  newWorkout: any; // Replace 'any' with the actual workout type
}

const useWorkoutMutation = () => {
  const queryClient = useQueryClient();

  const mutate = useMutation<void, unknown, MutationVariables, MutationContext>(
    {
      mutationFn: async ({ id, getUpdatedWorkout }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(["workout", id]);

        // Snapshot the previous value
        const previousWorkout = queryClient.getQueryData(["workout", id]);
        const newWorkout = await getUpdatedWorkout(previousWorkout);
        // Optimistically update to the new value
        queryClient.setQueryData(["workout", id], newWorkout);
      },
    }
  );

  return mutate;
};

const useWorkoutMutations = () => {
  const queryClient = useQueryClient();
  const debouncedUpdateWorkoutByID = useRef(
    debounce(updateWorkoutByID, 500)
  ).current;

  const updateWorkoutMutation = useMutation(
    (data: { workoutID: string; workoutData: Workout }) =>
      debouncedUpdateWorkoutByID(data.workoutID, data.workoutData),
    {
      onMutate: async (variables) => {
        // Snapshot the previous value
        const previousWorkout = queryClient.getQueryData<Workout>([
          `workout`,
          variables.workoutID,
        ]);

        // Optimistically update the state
        queryClient.setQueryData<Workout>([`workout`, variables.workoutID], {
          ...previousWorkout,
          ...variables.workoutData,
        });

        return { previousWorkout };
      },
      onError: (error: Error, variables, context: any) => {
        console.error("Error updating workout:", error);
        toast.error(`Something went wrong`);
        Sentry.captureException(error);
        if (context?.previousWorkout) {
          queryClient.setQueryData<Workout>(
            [`workout`, variables.workoutID],
            context.previousWorkout
          );
        }
      },
    }
  );

  return {
    updateWorkout: updateWorkoutMutation,
  };
};

export {
  useDetailedWorkoutSession,
  useWorkoutByID,
  useWorkoutMutation,
  useWorkoutMutations,
};
