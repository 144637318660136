import { z } from "zod";
import { Tool } from "./constants";

export const searchKnowledgeBase: Tool = {
  name: "searchKnowledgeBase",
  toolInUseText: "Searching the Ladder knowledge base...",
  description:
    "Search the Ladder knowledge base for relevant information. You should default to using this for most questions asked. Only call this once per query. If you can't find the right answer, let the user know.",
  parameters: z.object({
    query: z.string(),
    match_count: z
      .number()
      .optional()
      .describe("The number of documents to return"),
  }),
  env: "server",
  execute: async ({
    query,
    match_count = 4,
  }: {
    query: string;
    match_count?: number;
  }) => {
    const headers = {
      "Content-Type": "application/json",
    };

    const response = await fetch(
      `${process.env.NEXT_PUBLIC_BASE_URL}/api/arnie/search/knowledge-base`,
      {
        method: "POST",
        headers,
        body: JSON.stringify({ query, match_count }),
      }
    );

    if (!response.ok) {
      return `Failed to search. statusText: ${response.statusText}`;
    }

    return response.json();
  },
};
