import { z } from "zod";
import { Tool } from "./constants";

const replaceMovement: Tool = {
  name: "replaceMovement",
  description:
    "Useful for swapping out movements for your client with movements from the library. Use this after you have completed your search for suitable replacements, and you know both the original ID and the ID of the movement you want to replace it with.",
  parameters: z.object({
    replacements: z.array(
      z.object({
        originalID: z
          .string()
          .describe(
            "The original movement ID that your client requested an alternative for."
          ),
        id: z
          .string()
          .describe("If you found a suitable alternative movement ID."),
      })
    ),
  }),
  execute: async ({ replacements }) => {
    try {
      return { replacements };
    } catch (error) {
      return `Failed to execute script: ${error.message}`;
    }
  },
};

export { replaceMovement };
