import { z } from "zod";
import { Tool } from "./constants";
import { listTeams } from "./list-teams";

export const renderTeamCards: Tool = {
  name: "renderTeamCards",
  toolInUseText: "Rendering team cards...",
  description:
    "Render user friendly UI cards for team(s). Use this to render UI cards for team(s) to show to the user. IMPORTANT: After you use this tool, send a short message to the user explaining that they should be able to see the teams they asked for.",
  parameters: z.object({
    ids: z
      .array(z.string())
      .describe("The ID(s) of the team(s) you want to render UI cards for."),
  }),
  execute: async ({ ids }: { ids: string[] }) => {
    const teams = await listTeams();
    const details = teams.filter((team) => ids.includes(team.id));
    return details;
  },
};
