import { z } from "zod";
import { Tool } from "./constants";

const deleteMovement: Tool = {
  name: "deleteMovement",
  description: "Useful for deleting movements as requested by your client.",
  parameters: z.object({
    summary: z
      .string()
      .describe(
        "Summary of the change you are making in past tense. 'deleted movements x, y'"
      ),
    ids: z.array(
      z.string().describe("The ID of the movement the user wants to delete.")
    ),
  }),
  execute: async (params) => {
    try {
      return params;
    } catch (error) {
      return `Failed to delete movement: ${error.message}`;
    }
  },
};

export { deleteMovement };
