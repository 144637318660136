import { openai } from "@ai-sdk/openai";
import { generateObject } from "ai";
import { listTeams } from "queries/teams/services";
import { z } from "zod";
import { Tool } from "./constants";

export const searchTeams: Tool = {
  name: "searchTeams",
  toolInUseText: "Searching for teams...",
  description:
    "Search for ladder teams. Use natural langauge to find a team. Be descriptive and detailed for best results.",
  parameters: z.object({
    query: z
      .string()
      .describe(
        "your search query. be detailed and descriptive for best results."
      ),
  }),
  env: "server",
  execute: async ({ query }: { query: string }) => {
    const initialTeams = await listTeams();
    const response = await generateObject({
      model: openai("gpt-4o-mini"),
      schema: z.object({
        ids: z
          .array(z.string())
          .describe("the ids of the teams you wish to return"),
      }),
      prompt:
        "Here is the json for a set of different fitness teams" +
        JSON.stringify(initialTeams) +
        "Given the following search, return the list of team ids that match the search criteria: " +
        query,
    });

    const teamIds = response.object.ids;
    const details = initialTeams.filter((team) => teamIds.includes(team.id));

    return details;
  },
};
